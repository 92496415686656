import { message } from 'antd';
import axios from 'axios';
export const baseURL = 'https://sourcebuzz.sourcerer.tech/'
const userData = localStorage.getItem('user');
const user = userData && JSON.parse(userData);
const API_KEY = {
  URL: axios.create({
    // baseURL: 'http://167.71.234.246/',
    baseURL: baseURL,
    params: {
      access_token: user?.data?.access_token,
    },
  }),
  path: {
    post: 'items/post',
    category: '/items/category',
    posts: 'items/post?fields=*,user_created.first_name,user_created.avatar,user_created.last_name,user_created.mobile,photos.directus_files_id&sort=sort,-date_created',
    users: '/users',
    sendOtp: 'otp/send-otp',
    verifyOtp: 'otp/verify-otp',
    assets: '/assets',
    notificationTokenStore: '/items/user_notification_settings'

  },
};

export const PYTHON_API_KEY = {
  URL: axios.create({
    baseURL: 'https://yesnotifications.sourcerer.tech/',
    // baseURL: baseURL,
    params: {
      access_token: user?.data?.access_token,
    },
  }),
  path: {
    notify: '/send_notification',

  },
};


// interceptor which logout 
API_KEY.URL.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    message.warning('Session expired, please login again');
    window.location.href = '/';
    localStorage.removeItem('user');
    window.location.reload();
  }
  return Promise.reject(error);


});

export default API_KEY;
