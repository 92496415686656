import React, { Component }  from 'react';
import './styles.less';
import backBtn from '../../assets/images/backBtn.svg';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const HeaderPost = (props) => {
  const { title, titleWithRoute } = props;
  const history = useNavigate();
  return (
    <div className="headerPost"
    onClick={() => history(-1)}
    >
       
          <img src={backBtn} alt="back" className="backImg" />
          <p className="headerTitle2">{titleWithRoute}</p>
    </div>
  );
};
export default HeaderPost;