import React, { useState, useEffect } from 'react';
// import NumericInput from '../../components/numericInput';
import logo from '../../assets/images/srcwiz.png';
import { Button, Radio } from 'antd';
// import AppBtn from '../../components/appBtn/index';
// import AppModal from '../../components/appModal';
// import Modal from 'react-modal/lib/components/Modal';
// import OtpInput from 'react-otp-input';
// import { EyeInvisibleOutlined } from '@ant-design/icons';
// import { Input, Space } from 'antd';
import './styles.less';
import { useNavigate } from 'react-router-dom';
import feedImg from '../../assets/images/feed.svg';
import postImg from '../../assets/images/post.svg';
import profileImg from '../../assets/images/profile.svg';
import { useLocation } from 'react-router-dom';
import profileActive from '../../assets/images/profileActive.svg';
import feedActive from '../../assets/images/feedActive.svg';
const BottomNav = (props) => {
  const history = useNavigate();
  const location = useLocation();
  const menu = [
    {
      id: 0,
      name: 'Feed',
      img: location.pathname === '/' ? feedActive : feedImg,
      route: '/',
      active: location.pathname === '/' ? true : false,
    },
    {
      id: 1,
      name: 'Post',
      img: postImg,
      route: '/create-post',
      active: location.pathname === '/create-post' ? true : false,
    },
    {
      id: 0,
      name: 'Profile',
      img: location.pathname === '/profile' ? profileActive : profileImg,
      route: '/profile',
      active: location.pathname === '/profile' ? true : false,
    },
  ];

  return (
    <div className="bottomNav">
      {menu &&
        menu.length > 0 &&
        menu.map((item) => {
          return (
            <div
              onClick={() => history(item.route)}
              key={item.id}
              role="presentation"
              className="menuItem"
            >
              <img src={item.img} alt={item.name} className="menuItemImg" />
              <p
                className={`${
                  item.active ? 'menuItemTextActive' : 'menuItemText'
                }`}
              >
                {item.name}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default BottomNav;