import React from "react";
import { BrowserRouter } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { Layout } from "antd";
import { useGlobalState } from '../store/index'
export default function AppRouter() {
  const [auth, setAuth] = useGlobalState('isLoggedin');
  console.log(window.innerWidth);
  const innnerWidth = window.innerWidth;
  return (
    <Layout className="layout">
      {/* {innnerWidth > 1200 ? (
        <h1 style={{ textAlign: "center", margin: "80px" }}>
          We don't support this screen. Use this app in Mobile or in a Tablet.
        </h1>
      ) : (
        <BrowserRouter>
          {auth ? <PrivateRoutes /> : <PublicRoutes />}
        </BrowserRouter>
      )} */}
       <BrowserRouter>
          {auth ? <PrivateRoutes /> : <PublicRoutes />}
        </BrowserRouter>
    </Layout>
  );
}
