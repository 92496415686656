import React, { useEffect, useState } from "react";
import BottomNav from "../../components/bottomNav";
import Header from "../../components/Header";
import "./styles.less";
import { Row, Col, Form, Input, Button, Spin, message } from "antd";
import UploadImg from "../../components/Upload";

import API_KEY, { baseURL } from "../../api";
import axios from "axios";
import userImg from "../../assets/images/user.png";
import { useNavigate } from "react-router-dom";
import { MdLogout } from 'react-icons/md'
export default function Profile() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [mobile, setMobile] = useState("");
  const [isImage, setIsimage] = useState("");
  const [user, setUser] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [progressData, setProgressData] = useState({});


  const [postDis, setPostDis] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    API_KEY.URL.get(
      API_KEY.path.users + `/${userData?.id}`,
      {}
    ).then((res) => {
      console.log(res);
      if (res && res?.status === 200) {
        if (
          res?.data?.data?.avatar !== null ||
          res?.data?.data?.avatar !== ""
        ) {
          API_KEY.URL.get(
            API_KEY.path.assets + "/" + res?.data?.data?.avatar,
            {}
          ).then((res) => {
            console.log(res);
            //   setIsimage(res?.data)
          });
        }
        setIsimage(res?.data?.data?.avatar);
        setUser(res?.data?.data);
        setName(res?.data?.data?.first_name);
        setEmail(res?.data?.data?.email);
        setMobile(res?.data?.data?.mobile);
        setCompany(res?.data?.data?.company);
      }
    });
  }, []);



  const validate = () => {
    const payload = {
      first_name: name,
      // email: email,
      company: company,
      avatar: fileList[0]?.id,
    };
    API_KEY.URL.patch(
      API_KEY.path.users + `/${userData?.id}`,
      payload
    ).then((res) => {

      message.success("Profile Updated Successfully");
      if (res && res?.status === 200) {
        setUser(res.data.data);
      }
    }).catch((err) => {
      console.log(err);
      message.error("Something went wrong!");
    })
  };

  const uploadImage = async (options) => {
    setIsLoading(true);
    setPostDis(false);
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgressData((prevState) => {
          return { ...prevState, [options.file.uid]: percent };
        });
        // if (percent === 100) {
        //   setTimeout(() => setProgressData((prevState) => {
        //     return {...prevState, [options.file.uid]: percent};
        //   }), 1000);
        // }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("image", file);
    console.log(file);

    axios
      .post(`${baseURL}files`, fmData, config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          onSuccess("Ok");
          setFileList([response?.data?.data]);
          console.log("dat", response?.data?.data);
          setPostDis(true);
          setIsimage(response?.data?.data?.id);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        onError({ err });
        message.error("Something went wrong!");
      });
    console.log(fileList);
  };


  const logout = () => {
    localStorage.clear();
    navigate("/")
    window.location.reload();
  }
  return (
    <>
      <Header title="Profile" />
      <Row justify="center" style={{ marginTop: "60px" }}>
        {/* <div className="headerDiv"> */}
        {/* </div> */}
      </Row>

      {isImage || (isImage !== null && isImage !== "") ? (
        //   isImage.file.name
        <div
          role="button"
          onKeyDown={() => setIsimage("")}
          tabIndex={0}
          onClick={() => setIsimage("")}
          className="imgCont"
        >
          <img
            src={
              isImage
                ? "https://sourcebuzz.sourcerer.tech/assets/" + isImage
                : userImg
            }
            className="imgLay"
            alt="profile"
          />
          {/* <img src={editImg} alt="edit" className="editLay" /> */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="space">Change picture</a>
        </div>
      ) : (
        <UploadImg
          uploadImage={uploadImage}
          fileList={fileList}
          setFileList={setFileList}
        />
      )}
      {isLoading && <Spin tip="Uploading Image!"></Spin>}
      <Col style={{ marginBottom: 300 }}>
        <Form
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}

          autoComplete="off"
        >
          <Form.Item
            label="Name"

          >
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name"
              size="large"
            />
          </Form.Item>

          <Form.Item
            label="Email id"


          >
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              disabled={true}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Company name"

          >
            <Input
              onChange={(e) => setCompany(e.target.value)}
              value={company}
              placeholder="Enter company name"
              size="large"
            />
          </Form.Item>

          <Form.Item label="Mobile">
            <Input
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="passwordInp"
              disabled={true}
              placeholder="Enter Mobile number"
              size="large"
            />
          </Form.Item>

          <Button
            // className="dark-btn"
            onClick={() => validate()}
            type="primary"
            htmlType="submit"
            style={{ width: '100%', marginTop: '1em', background: 'black', border: 0 }}
            size="large"
          >
            Save Changes
          </Button>
          <Button
            type="ghost"
            onClick={() => logout()}
            size="large"
            style={{ width: '100%', marginTop: '1em' }}
          >
            <Row gutter={[40]} justify="center" align="middle">
              <MdLogout style={{ marginRight: 8 }} />
              <span>Logout</span>
            </Row>

          </Button>
        </Form>
        {/* <div className="logoutWrap" onClick={() => logout()}>
          <img src={logoutImg} alt="logout" className="logIcon" />
          <p className="logText">Logout</p>
        </div> */}
      </Col>

      <Row justify="center" style={{ marginTop: '3em' }}>
        <BottomNav />
      </Row>
    </>
  );
}
