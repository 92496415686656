// import { Upload } from 'antd';
// import ImgCrop from 'antd-img-crop';
// import { useState } from 'react';
// import './styles.less';
// import plus from '../../assets/images/plus.svg';
// const UploadImages = (props) => {
//   const { uploadImage, fileList, isBtn } = props;
//   const [previewVisible, setPreviewVisible] = useState(false);
//   const [previewImage, setPreviewImage] = useState('');
//   const [previewTitle, setPreviewTitle] = useState('');
//   // const [fileList, setFileList] = useState([
//   //   // {
//   //   //   uid: '-1',
//   //   //   name: 'image.png',
//   //   //   status: 'done',
//   //   //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//   //   // },
//   // ]);

//   // const onChange = ({ fileList: newFileList }) => {
//   //   setFileList(newFileList);
//   // };

//   const onPreview = async (file) => {
//     let src = file.url;

//     if (!src) {
//       src = await new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj);

//         reader.onload = () => resolve(reader.result);
//       });
//     }

//     const image = new Image();
//     image.src = src;
//     const imgWindow = window.open(src);
//     imgWindow?.document.write(image.outerHTML);
//   };

//   const handlePreview = async (file) => {
//     debugger;
//     if (!file.url && !file.preview) {
//       file.preview = await getBase64(file.originFileObj);
//     }

//     setPreviewImage(file.url || file.preview);
//     setPreviewVisible(true);
//     setPreviewTitle(
//       file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
//     );
//   };

//   return (
//     // <ImgCrop rotate>
//     <Upload
//       // listType="picture-card"
//       // fileList={fileList}
//       // onChange={onChange}
//       // onPreview={onPreview}
//       // onPreview={handlePreview}
//       ///

//       multiple
//       customRequest={uploadImage}
//       // className="upload-list-inline"
//       // showUploadList={false}
//       // fileList={fileList}

//       listType="picture-card"
//       fileList={fileList}
//       onPreview={handlePreview}
//       // onChange={uploadImage}
//     >
//       <img alt="img" src={previewImage} />
//       {isBtn === true ? (
//         <div className="flexing">
//           <img src={plus} alt="plus" className="imgPlus" />
//           <p className="txtPlus">Add image</p>
//         </div>
//       ) : (
//         <p className="txtPlus">Add more images</p>
//       )}
//     </Upload>
//     // </ImgCrop>
//   );
// };

// export default UploadImages;


import React, { Component }  from 'react';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { useState } from 'react';
import './styles.less';
import plus from '../../assets/images/plus.svg';
import userImg from '../../assets/images/user.png'
import editImg from "../../assets/images/edit.png";


const UploadImg = (props) => {
  const { uploadImage, fileList, setFileList } = props;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  // const [fileList, setFileList] = useState([
  //   {
  //     uid: '-1',
  //     name: 'image.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  //   {
  //     uid: '-2',
  //     name: 'image.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  //   {
  //     uid: '-3',
  //     name: 'image.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  //   {
  //     uid: '-4',
  //     name: 'image.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  //   {
  //     uid: '-xxx',
  //     percent: 50,
  //     name: 'image.png',
  //     status: 'uploading',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  //   {
  //     uid: '-5',
  //     name: 'image.png',
  //     status: 'error',
  //   },
  // ]);

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div className="flexing">
      <img src={plus} alt="img" className="imgPlus" />
      <p className="txtPlus">Add profile</p>
    </div>
  );

  const AddMoreImages = <p className="txtPlus1">Add more images</p>;
  return (
    <>
      <Upload
        // multiple
        customRequest={uploadImage}
        // className="upload-list-inline"
        // showUploadList={false}
        // fileList={fileList}
        className='ImageProfile'
        // onChange={handleChange}
        // listType="picture-card"
        // fileList={fileList}
        // onPreview={handlePreview}
      >       
    {/* <UserOutlined 
    className='imgPlace'
    /> */}
    
    <div
    className="imgCont"
  >
      {uploadButton}
    {/* <img src={userImg} className="imgPlace" alt="profile" /> */}
    {/* <img src={editImg} alt="edit" className="editLay" /> */}
  </div>
      </Upload>
      {/* <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal> */}
    </>
  );
};

export default UploadImg;

