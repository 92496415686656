import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton, Typography, Tag } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import PostCard from "./PostCard";
import API_KEY from "../../api/index";
import BottomNav from "../../components/bottomNav";
// import Header from "../../components/Header";
import "./styles.less";
import { getMessaging, onMessage } from "firebase/messaging";
import { firebase } from "../../firebase";

export default function Posts() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const userData = JSON.parse(localStorage.getItem("user"));
    const [categoryActive, setCategoryActive] = useState('');
    const [categoryItem, setCategoryItem] = useState(null);

    const messaging = getMessaging(firebase);
    const [notificationFcmTokens, setNotificationFcmTokens] = useState([]);
    const [flag, setFlag] = useState(false);
    const registrationTokens = [
        localStorage.getItem('fcmToken'),
        'YOUR_REGISTRATION_TOKEN_n'
      ];
      console.log(registrationTokens);

    useEffect(() => {
        let topic = 'Post'
        subscribeTokenToTopic(registrationTokens[0], topic)

        setLoading(true)
        const filters = categoryItem && categoryItem !== '' ? {categories: { _contains: categoryItem}} : null
        API_KEY.URL.get(API_KEY.path.posts, {
            params: {
                filter: filters,
            }
        }).then(res => {
            if (res.status === 200) {
                console.log(res);
                setData(res.data.data);
                let catgoryList = [];
                res.data.data.map((item) => {
                    if (item.categories !== null) {
                        catgoryList.push(item.categories);
                    }
                })
                if (categoryItem === '' || categoryItem === null) {
                    setCategories([...new Set(catgoryList)]);

                }

                console.log('eew', catgoryList)
                setLoading(false)
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })

        // const messaging = getMessaging();
        onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
            // ...
        });

    }, [categoryItem])

    useEffect(() => {
        API_KEY.URL.get(
            API_KEY.path.notificationTokenStore + `?limit=400&fields[]=device_token&fields[]=status&fields[]=id&sort[]=id&page=1&filter=%7B%22status%22:%7B%22_neq%22:%22archived%22%7D%7D&meta[]=filter_count&meta[]=total_count`,{}
        ).then((res) => {
            console.log(res.data.data)
            if(res && res?.status === 200) {
                let flag;
                    res && res.data.data.length > 0 && res.data.data.map((item) => {
                        if(item?.device_token === localStorage.getItem('fcmToken'))
                        {
                            console.log('not registering user')
                            flag = true
                        }
                    })
                    if (flag !== true) {
                        let user = localStorage.getItem('user')
                            let userid = JSON.parse(user)
                            console.log(userid)
                            const payload = {
                                device_token: localStorage.getItem('fcmToken'),
                                status: 'published',
                                user: userid.id
                            }
                            console.log('pay', payload)
                            API_KEY.URL.post(API_KEY.path.notificationTokenStore, payload).then((res) => {
                                console.log(res);
                            }).catch((err) => {
                                console.log(err);
                                // message.error("Something went wrong!");
                              })
                    }
            }
        })
        
    }, [])


    function subscribeTokenToTopic(token, topic) {
        let FCM_SERVER_KEY = 'AAAAZEcy3i0:APA91bFN2fiNqVBgmVRMVmTCBNxr_BsapVaVNlMIN6pZsmPhvEPQUdPjkX02dJBf-aCtP9tVhwWGkyksR8hH_ZtnzUkju8LF9Wn3vABaKxsz5Mg7K7nTwcMGzzoT8K1k8LzaTCCMScL2'
        fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `key=${FCM_SERVER_KEY}`
          })
        })
          .then((response) => {
            if (response.status < 200 || response.status >= 400) {
              console.log(response.status, response);
            }
            console.log(`"${topic}" is subscribed`);
          })
          .catch((error) => {
            console.error(error.result);
          });
          return true;
        }

    const handleCategories = (item) => {
            if(item == categoryActive) {
                setCategoryItem('')
            setCategoryActive('')
            }
            else {
                setCategoryItem(item)
            setCategoryActive(item)
            }
            
    }

    return (
        <>

            <Row justify="center" >
                {/* <div className="headerDiv">
                    <Header title="Feed" />
                </div> */}

            </Row>
            {loading ? <div style={{ marginTop: 40, marginBottom: "110px" }}  >
                <Row justify="space-between" align="middle">
                    <Skeleton.Avatar size="large" />
                    <Skeleton active />
                </Row>
                <Row justify="space-between" align="middle" >
                    <Skeleton.Avatar size="large" />
                    <Skeleton active />
                </Row>
                <Row justify="space-between" align="middle" >
                    <Skeleton.Avatar size="large" />
                    <Skeleton active />
                </Row>
            </div> : <Row style={{ marginTop: 40, marginBottom: "110px" }}>

                <Typography.Title level={4}> Welcome  {userData?.first_name} </Typography.Title>
                <div style={{    
                    width: '100%',
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justify: 'flex-start'}}>
                {
                    categories && categories.length > 0 &&
                    categories.map((item) => {
                        return(
                            <Tag
                            onClick={() => handleCategories(item)}
                            style={{
                                fontSize: 16,
                                marginBottom: 0,
                                textAlign: "center",
                                padding: 12,
                                cursor: 'pointer',
                                borderRadius: 8,
                                marginRight: 12,
                                backgroundColor: item === categoryActive ? "#0B7764" : "#F0F8F6",
                                color: item === categoryActive ? "white" : "#0B7764",
                                border: "1px solid #7DB8AD",
                              }}
                            >
                                {item}
                            </Tag>
                        )
                    })
                }    
                </div> 
                <Col span={24} style={{ marginTop: 16 }}>
                    {
                        data.map((item, index) => <PostCard post={item} index={index} />)
                    }

                    
                </Col>

            </Row>}
            <Row justify="center">
                <BottomNav />
            </Row>
        </>

    );
}
