import React from "react";
import { Modal, Row, Col, Typography, Button } from "antd";
import OtpInput from 'react-otp-input';

const { Title, Text } = Typography;
export default function VerifyOtpModal({
    showModal,
    mobile,
    setShowModal,
    verifyOtp,
    setOtp,
    otp,
    loading
}) {
    return (
        <Modal
            visible={showModal}
            title={null}
            closable={false}
            footer={null}
            centered
            bodyStyle={{ borderRadius: "10px" }}
            style={{ borderRadius: "10px" }}
        >
            <Row className="space">
                <Col>
                    <Title level={3}>Verification code</Title>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Text level={3}>Enter the OTP sent on +91 {mobile}</Text>
                </Col>
            </Row>

            {/* <Row>
                <Col>
                    <Text level={3}>OTP valid for 03:00 minutes</Text>
                </Col>
            </Row> */}

            <Row justify="center" gutter={[20]} className="space">
                <Col span={24}>
                    <OtpInput
                        value={otp}
                        onChange={(e) => setOtp(e)}
                        numInputs={4}
                        containerStyle="otpContainer"
                        inputStyle="inputStyle"
                        separator={<span> </span>}
                        focusStyle={'focusStyle'}
                        // shouldAutoFocus={true}
                        isInputNum={true}
                        isInputSecure={true}

                    />
                </Col>
            </Row>

            <Row justify="space-between" style={{ marginTop: "3em" }}>
                <Col span={8}>
                    <Button
                        onClick={() => setShowModal(false)}
                        size="large"
                        style={{ width: "100%" }}
                    >
                        Close
                    </Button>
                </Col>
                <Col span={8}>
                    <Button
                        onClick={() => verifyOtp()}
                        size="large"
                        loading={loading}
                        className="dark-btn"
                        style={{
                            backgroundColor:
                                (otp?.[1] === "" || otp?.[2] === "" || otp?.[3] === "" || otp?.[4] === "") ? "" : "black",
                            width: "100%"
                        }}
                    >
                        Verify
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}
