import React, {useEffect} from 'react'
import AppRouter from './routes/index'
import { getMessaging, getToken, onMessage} from "firebase/messaging";
import "./App.less"
// import firebase from './firebase'
import { firebase } from './firebase';
export default function App() {
  const messaging = getMessaging(firebase);
 
  useEffect(() => {
    if(localStorage.getItem('fcmToken')) {
      console.log(localStorage.getItem('fcmToken'))
    }
    else {
 getToken(messaging, { vapidKey: 'BFZ0DeRqXptOsFblfudOJzurIBnHdyPuw4S2nbGVAmlgoRQc4FJD44W-VMn6uTFh6ieq4lElXl10-eIqS34Rrto' }).then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        localStorage.setItem('fcmToken', currentToken)
        console.log(currentToken)
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            var notification = new Notification("Hi there!");
          }
        });
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
    }
   

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // ...
    });
    
  },[])

  return (
    <AppRouter />
  )
}
