import React, { useState } from "react";
import { Row, Col, Form, Input, Typography, Divider, Button, notification } from "antd";
import "./styles.less";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { baseURL } from "../../api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { preferences } from "../../constants";
export default function SignUp() {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const navigation = useNavigate();
    const onFinish = (values) => {
        console.log("Success:", values);
        // to do change community and role if needed
        const payload = {
            ...values,
            communities: { create: [{ directus_users_id: "+", community_id: { id: preferences.communityId } }], update: [], delete: [] },
            role: "dc64a035-656d-4d91-af87-a800d8501ef7"
        }
        setLoading(true)
        axios.post(`${baseURL}users`, payload).then(res => {
            console.log(res);
            if (res.status === 200) {
                notification.success({
                    message: "Success",
                    description: "User created successfully. Please login to continue.",
                    placement: "bottomRight"
                });
                form.resetFields();
                setTimeout(() => {
                    setLoading(false);
                    navigation("/");
                }, 1500);
            }


        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
        form.scrollToField(errorInfo?.errorFields?.[0]?.name);
    };
    return (
        <Row style={{ padding: 16 }} className="sign-up-form">
            <Col span={24}>
                <Typography.Title style={{ margin: 0 }} level={3}>
                    Enter profile details
                </Typography.Title>
                <Divider style={{ margin: "8px 0" }} />
            </Col>

            <Col span={24}>
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="first_name"
                        label="First name"
                        rules={[
                            {
                                required: true,
                                message: " ",
                            },
                        ]}
                    >
                        <Input size="large" type={"text"} />
                    </Form.Item>

                    <Form.Item
                        name="last_name"
                        label="Last name (optional)"
                        rules={[
                            {
                                required: false,
                                message: " ",
                            },
                        ]}
                    >
                        <Input size="large" type={"text"} />
                    </Form.Item>
                    <Form.Item
                        name="mobile"
                        label="Mobile number"
                        rules={[
                            {
                                required: true,
                                message: "please enter your 10 digit mobile number",
                                len: 10,
                            },
                        ]}
                    >
                        <Input size="large" type={"number"} />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: " ",
                                type: "email",
                            },
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: " ",
                            },
                        ]}
                    >
                        <Input.Password
                            size="large"
                            iconRender={(visible) =>
                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name="company"
                        label="Company (optional)"
                        rules={[
                            {
                                required: false,
                                message: " ",
                            },
                        ]}
                    >
                        <Input size="large" type={"text"} />


                    </Form.Item>

                    <Form.Item style={{ marginTop: "2em" }}>
                        <Button
                            size="large"
                            style={{ background: "black", border: 0 }}
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            loading={loading}
                        >
                            Create account
                        </Button>
                    </Form.Item>
                    <Form.Item style={{ marginTop: "2em" }}>
                        <Button
                            onClick={() => navigation("/")}
                        >
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </Col>

            {/* <Row
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "white",
                    border: "1px solild blue",
                }}
                justify="center"
            >
                <Col span={24}>
                   
                </Col>
            </Row> */}
        </Row>
    );
}
