// import firebase from 'firebase';
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyDpOhNLKND0EotmlP6h3xGvwSiPJfxefcM",
    authDomain: "yesapp-yes.firebaseapp.com",
    projectId: "yesapp-yes",
    storageBucket: "yesapp-yes.appspot.com",
    messagingSenderId: "430691245613",
    appId: "1:430691245613:web:ea8d456f8b74adad1c22a4",
    measurementId: "G-H08KSJFRCH"
  };
  
export const firebase = initializeApp(firebaseConfig);

