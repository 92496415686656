import React from "react";
import { Row, Col, Card, Avatar, Typography, Button, Image } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FaWhatsapp } from "react-icons/fa";
import { UserOutlined } from '@ant-design/icons';
import moment from "moment";
import { IoMdArrowDroprightCircle, IoMdArrowDropleftCircle } from "react-icons/io";

const { Title, Text } = Typography;
function randomRgbaString(alpha) {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    let a = alpha;
    return `rgba(${r},${g},${b},${a})`;
}

const getInitials = (first_name, last_name) => {
    let initials = first_name?.charAt(0) + last_name?.charAt(0);
    return initials?.toUpperCase();
};

export default function PostCard({ post, index }) {
    return (
        <Card
            style={{
                marginTop: index === 0 ? "0px" : "20px",
                marginBottom: index === 0 ? "0px" : "20px",
                borderRadius: "10px",
            }}
            key={index}
            className="postCard"
        >
            <Row gutter={[16]} align="middle">
                <Col>
                    <Avatar
                        size={42}
                        style={{
                            color: "white",
                            backgroundColor: !post?.user_created?.avatar && randomRgbaString(1.0),
                        }}
                        icon={!post?.user_created && <UserOutlined />}
                        src={post?.user_created?.avatar && <Image src={`https://sourcebuzz.sourcerer.tech/assets/${post?.user_created?.avatar}`} style={{ objectFit: 'cover' }} />}
                    >
                        {post?.user_created && getInitials(
                            post?.user_created?.first_name,
                            post?.user_created?.last_name
                        )}
                    </Avatar>
                </Col>
                <Col>
                    <Title style={{ margin: 0, fontSize: 14 }} level={5}>
                        {post?.user_created?.first_name} {post?.user_created?.last_name}
                    </Title>
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {moment(post?.date_created).format("MMMM Do YYYY, h:mm a")}
                    </Text>
                </Col>
            </Row>

            <Row className="space">
                <Col>
                    <Text>{post?.description}</Text>
                </Col>
            </Row>

            <Row
                className="space"
            >
                <Col span={24}>
                    <Carousel
                        showThumbs={false}
                        showArrows={true}
                        className="image-carousel"
                    // renderArrowNext={() => <IoMdArrowDroprightCircle />}
                    // renderArrowPrev={() => <IoMdArrowDropleftCircle />}
                    // autoPlay={true}
                    // infiniteLoop={true}
                    >
                        {post.photos.map((photo, index) => (
                            <img
                                key={index}
                                alt="product"
                                src={`https://sourcebuzz.sourcerer.tech/assets/${photo?.directus_files_id}?quality=75`}
                            />
                        ))}
                    </Carousel>
                </Col>
            </Row>

            <Row
                className="space"
                justify="center"

            >
                {post?.user_created &&
                    <Button
                        disabled={post?.user_created ? false : true}
                        className="whatsapp"
                        onClick={() => {
                            window.open(
                                `https://wa.me/91${post?.user_created?.mobile}?text=I'm%20inquiring%20about%20the%20listing%20http://sourcebuzz.sourcerer.tech/assets/${post.photos?.[0]?.directus_files_id}`
                            );
                        }}
                    >
                        <Row align="middle" justify="center">
                            <FaWhatsapp style={{ marginRight: 5, fontSize: 18 }} />
                            <Text style={{ fontSize: 12, color: '#128c7e' }}>Whatsapp</Text>
                        </Row>
                    </Button>}

            </Row>
        </Card>
    );
}
