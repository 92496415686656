import React, { Component }  from 'react';
import './style.less';
import backBtn from '../../assets/images/backBtn.svg';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const Header = (props) => {
  const { title, titleWithRoute } = props;
  const history = useNavigate();
  return (
    <div className="header">
      {titleWithRoute ? (
        <div
          role="presentation"
          onClick={() => history(-1)}
          className="headerDiv"
        >
          <img src={backBtn} alt="back" className="backImg" />
          <p className="headerTitle2">{titleWithRoute}</p>
        </div>
      ) : (
        <p className="headerTitle">{title}</p>
      )}
    </div>
  );
};
export default Header;