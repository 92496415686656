import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Tag } from "antd";
import "./styles.less";
import API_KEY, { baseURL, PYTHON_API_KEY } from "../../api";
import tick from "../../assets/images/tick.svg";
import UploadImages from "../../components/UploadImages/index";
import axios from "axios";
import { preferences } from "../../constants";

import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import categoryInfo from '../../categories.json';
import HeaderPost from "../../components/headerPost";
import { getMessaging, onMessage } from "firebase/messaging";
import { firebase } from "../../firebase";
// import admin from 'firebase-admin';

var admin = require('firebase-admin');

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default function CreatePost() {
  const [categoryData, setCategoryData] = useState([]);
  const [active, setActive] = useState({});
  const [activeSub, setActiveSub] = useState([]);
  const [requirement, setRequirement] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subCategoryData, setSubcategoryData] = useState([]);
  //   const [previewVisible, setPreviewVisible] = useState(false);
  //   const [previewImage, setPreviewImage] = useState('');
  //   const [previewTitle, setPreviewTitle] = useState('');

  const [fileList, setFileList] = useState([]);
  const [progressData, setProgressData] = useState({});
  const [error, setError] = useState(null);
  const [postDis, setPostDis] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));
  const messaging = getMessaging(firebase);
  const registrationTokens = [
    localStorage.getItem('fcmToken'),
    // 'YOUR_REGISTRATION_TOKEN_n'
  ];
  console.log(registrationTokens);

  // to do change community and role if needed
  useEffect(() => {
    // console.log('cat', categoryInfo[5].categories)
    // categoryInfo[5].categories && categoryInfo[5].categories.length > 0 &&
    // categoryInfo[5].categories.map((i) => {
    //   categoryInfo[5].categories[i].subCategories && categoryInfo[5].categories[i].subCategories.length > 0 &&
    //   categoryInfo[5].categories[i].subCategories.map((item) => {
    //     return(
    //       item = {
    //         name: item,
    //         status: false,
    //       }
    //     )
       
    //   })
    // })
    // console.log('cat', categoryInfo)

    setCategoryData(categoryInfo[5].categories);
    // API_KEY.URL.get(
    //   API_KEY.path.category + "?filter[community][_eq]=" + preferences.communityId,
    //   {}
    // ).then((res) => {
    //   if (res && res?.status === 200) {
    //     setCategoryData(res?.data?.data);
    //   }
    // });
  }, []);

  const openNotification = (placement) => {
    notification.info({
      message: `Successfully posted!`,
      description: "Your post has been posted successfully.",
      placement,
    });
  };

  const handleCategories = (data) => {
    setActiveSub([])
    if (data.name === active.name) {
      setActive({});
    } else {
      setActive(data);
      categoryData && categoryData.length > 0 && 
      categoryData.map((item) =>{
        console.log(item.subCategories)
        if(data === item) {
          setSubcategoryData(item.subCategories)
        }
      })
      
    }
  };

  const handleSubCategories = (data, index) => {
    if (data === activeSub) {
      setActiveSub([]); 
    }
    else {
      var index = activeSub.indexOf(data);
      if (index > -1) {
        console.log(activeSub)
        activeSub.splice(index, 1);
        console.log(activeSub)
        setActiveSub(activeSub => [...activeSub])
      }
      else {
        setActiveSub(activeSub => [...activeSub, data])
      }
      // console.log({id: index, name: data})
    }
    // console.log(data, activeSub, index);

  }

  useEffect(() => {

  },[activeSub ]);

  //   const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadImage = async (options) => {
    setPostDis(false);
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgressData((prevState) => {
          return { ...prevState, [options.file.uid]: percent };
        });
        // if (percent === 100) {
        //   setTimeout(() => setProgressData((prevState) => {
        //     return {...prevState, [options.file.uid]: percent};
        //   }), 1000);
        // }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("image", file);
    console.log(file);
    setIsUploading(true);
    axios
      .post(`${baseURL}files`, fmData, config)
      .then((response) => {
        if (response.status === 200) {
          onSuccess("Ok");
          setFileList((result) => [...result, response?.data?.data]);
          setPostDis(true);
          setIsUploading(false);
        }
      })
      .catch((err) => {
        setError("Something went wrong");
        onError({ err });
        setIsUploading(false);
      });
    console.log(fileList);
  };

  const deleteImg = (data, indx) => {
    let newTemp = fileList && fileList.length > 0 &&
    fileList.filter((itm) => 
      itm.id !== data.id
    )
    setFileList(newTemp);
  }

  const PostIt = () => {
    setIsLoading(true);
    let photos = [];
    let id;
    fileList.map((item) => {
      id = item.id;
      photos.push({
        directus_files_id: {
          id: id,
        },
      });
    });

    const payload = {
      status: "published",
      user_created: userData?.id,
      description: requirement,
      photos: {
        create: photos,
      },
      categories: active.name,
      sub_categories: JSON.stringify(activeSub),
    };


    console.log(payload);
    console.log(fileList);
    let topic = 'Post'

    let user = localStorage.getItem('user')
    let userid = JSON.parse(user)

        API_KEY.URL.post(`${API_KEY.path.post}`, payload).then((res) => {
          console.log(res);
          if (res && res?.status === 200) {
            PYTHON_API_KEY.URL.post(
              PYTHON_API_KEY.path.notify,
              {
                data: {
                title: userid.first_name+" has posted recently!", 
                body: requirement,
              }
              }).then((res) => {
        
              if (res && res?.status === 200) {
                console.log(res);
              }
            }).catch((err) => {
              console.log(err);
            })
            setIsLoading(false);
            openNotification("bottom");
            // navigate("/");
            setTimeout(() => navigate("/"), 2000);
          }
        });


  };

  //   const handleChangeUpload = (info) => {
  //     if (info.file.status === "uploading") {
  //       setLoading(true);
  //       return;
  //     }
  //     if (info.file.status === "done") {
  //       // Get this url from response in real world.
  //       getBase64(
  //         info.file.originFileObj,
  //         (imageUrl) => setImageUrl(imageUrl),
  //         setLoading(false)
  //       );
  //     }

  // }

  //   const handlePreview = async (file) => {
  //     debugger;
  //     if (!file.url && !file.preview) {
  //       file.preview = await getBase64(file.originFileObj);
  //     }
  //         setPreviewImage(file.url || file.preview);
  //     setPreviewVisible(true);
  //     setPreviewTitle(
  //       file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
  //     );
  //   };

  // const uploadButton = (
  //     <div>
  //       {loading ? <LoadingOutlined /> : <PlusOutlined />}
  //       <div style={{ marginTop: 8 }}>Upload</div>
  //     </div>
  //   );

  return (
    // <div>CreatePost</div>
    <>
      <Row className="post-form">
      <HeaderPost titleWithRoute="Create post" />

        <Col
          xs={{
            span: 24,
          }}
          sm={{
            span: 24,
          }}
          md={{
            span: 24,
          }}
          lg={{
            span: 24,
          }}
          xl={{
            span: 24,
          }}
        >
          <Row justify="center"  style={{ marginTop: "70px", marginBottom: '210px' }}>
            <Col span={24}>
              <Form
                name="basic"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                //   onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="What kind of requirement is this?"
                // name="username"
                // rules={[
                //     {
                //         required: true,
                //         message: "Please input your username!",
                //     },
                // ]}
                >
                  <div className="categories">
                    {categoryData &&
                      categoryData.length > 0 &&
                      categoryData.map((item) => {
                        return (

                          <Tag
                            onClick={() => handleCategories(item)}
                            style={{
                              fontSize: 16,
                              marginBottom: 16,
                              textAlign: "center",
                              padding: 12,
                              cursor: 'pointer',
                              borderRadius: 8,
                              marginRight: 12,
                              backgroundColor: item.name === active.name ? "#0B7764" : "#F0F8F6",
                              color: item.name === active.name ? "white" : "#0B7764",
                              border: "1px solid #7DB8AD"


                            }}
                          >
                            {item.name}
                          </Tag>
                        );
                      })}
                  </div>
                </Form.Item>
                      {active.name &&
                        subCategoryData.length > 0 &&
                        <Form.Item
                        label="Select Sub Requirements"
                      // name="username"
                      // rules={[
                      //     {
                      //         required: true,
                      //         message: "Please input your username!",
                      //     },
                      // ]}
                      >
                        <div className="categories">
                          {subCategoryData &&
                            subCategoryData.length > 0 &&
                            subCategoryData.map((item,index) => {
                              return (
                                <Tag
                                  onClick={() => handleSubCategories(item, index)}
                                  style={{
                                    fontSize: 16,
                                    marginBottom: 16,
                                    textAlign: "center",
                                    padding: 12,
                                    borderRadius: 8,
                                    cursor: 'pointer',
                                    marginRight: 12,
                                    backgroundColor: (activeSub && activeSub.includes(item)) ? "#0B7764" : "#F0F8F6",
                                    // item === subCategoryData[index] ? "#0B7764" : "#F0F8F6",
                                    color: (activeSub && activeSub.includes(item)) ? "white" : "#0B7764",
                                    border: "1px solid #7DB8AD"
                                  }}
                                >
                                  {item}
                                </Tag>
                              );
                            })}
                        </div>
                      </Form.Item>
                      }
               

                <Form.Item label="Requirements">
                  <Input.TextArea
                    onChange={(e) => setRequirement(e.target.value)}
                    placeholder="Add your text here"
                    rows={5}
                  />
                </Form.Item>
              </Form>

              <Row>
                <Col>
                  <UploadImages
                    //   isBtn={fileList.length > 0 ? false : true}
                    uploadImage={uploadImage}
                    fileList={fileList}
                    setFileList={setFileList}
                    isUploading={isUploading}
                    deleteImg={deleteImg}
                  />
                </Col>
              </Row>
              {/* <Form.Item
              className="postDiv"
              >
                <Button className="dark-btn"
                    onClick={() => PostIt()}
                type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item> */}
            </Col>

            <div className="postDiv">
              <Button
                className="dark-btn"
                onClick={() => PostIt()}
                type="primary"
                htmlType="submit"
                disabled={active && requirement ? false : true}
                loading={isLoading}
              >
                Post
              </Button>
            </div>
          </Row>
        </Col>
      </Row>
      {/* <BottomNav /> */}
    </>
  );
}
