import React from "react";
import { Routes, Route } from "react-router-dom";
import CreatePost from "../pages/CreatePost";
import Profile from "../pages/Profile";
import Posts from "../pages/Posts";
export default function PrivateRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Posts />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/create-post" element={<CreatePost />} />
        </Routes>
    );
}
