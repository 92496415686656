/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Row, Col, Form, Input, Button, message, Alert, Space, Typography } from "antd";
import "./styles.less"
import Logo from "../../assets/images/logo.svg"
import Logo1 from "../../assets/images/logo1.svg"
import VerifyOtpModal from "./VerifyOtpModal";
import { baseURL } from '../../api'
import { useGlobalState } from '../../store/index'
import { useNavigate } from "react-router-dom";
import yesAppLogo from '../../assets/images/Yes App Logo Final.svg';
import axios from 'axios'
export default function SignIn() {
    const [auth, setAuth] = useGlobalState('isLoggedin');
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [form] = Form.useForm();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState("");
    const navigation = useNavigate();
    const onFinish = (values) => {
        console.log("Success:", values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const onClickOTP = () => {
        sendOtp()

    }

    // send otp method
    const sendOtp = () => {
        const payload = {
            mobile: mobile,
        };
        axios.post(`${baseURL}otp/send-otp`, payload).then((response) => {
            if (response?.status === 200) {
                setShowModal(true)
                message.success('OTP sent');
            } else {
                setError('Something went wrong !!! please try again');
            }
        });
    };

    //verify otp method
    const verifyOtp = () => {
        setLoading(true);

        const payload = {
            mobile: mobile,
            password: password,
            otp: otp
        };
        axios.post(`${baseURL}otp/verify-otp`, payload).then((response) => {
            if (response?.status === 200 && response?.data?.data) {
                setAuth(true);
                localStorage.setItem('user', JSON.stringify(response?.data));
                setTimeout(() => {
                    window.location.reload();
                    setLoading(false);
                }, 1000);
            } else {
                setLoading(false);
                setError('Something went wrong !!! please try again');
                message.error('Something went wrong !!!');
            }
        });
    };

    return (
        <Row
            className="sign-form"
        >
            {error && (
                <Alert
                    style={{ textAlign: 'center' }}
                    type="error"
                    message={error}
                    banner
                />
            )}
            <VerifyOtpModal loading={loading} otp={otp} verifyOtp={verifyOtp} setOtp={setOtp} showModal={showModal} mobile={mobile} setShowModal={setShowModal} />
            <Col
                xs={{
                    span: 24,
                }}
                sm={{
                    span: 24,
                }}
                md={{
                    span: 24,
                }}
                lg={{
                    span: 24,
                }}
                xl={{
                    span: 24,
                }}
            >
                <Row justify="center" >
                    <Col>
                        <img src={yesAppLogo} alt="logo" />
                    </Col>
                </Row>

                <Row justify="center" style={{ marginTop: '5em' }}>
                    <Col>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            form={form}
                        >
                            <Form.Item
                                label="Enter mobile number"
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your mobile number!",
                                    },
                                ]}
                            >
                                <Input type="number" size="large" onChange={(e) => {
                                    setMobile(e.target.value)
                                }} />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password!",
                                    },
                                ]}
                            >
                                <Input.Password size="large" onChange={(e) => {
                                    setPassword(e.target.value)
                                }} />
                            </Form.Item>

                            <Form.Item style={{ marginTop: "3em" }}>
                                <Button
                                    disabled={
                                        (mobile.length < 10 || password.length < 4) ? true : false
                                    }
                                    size="large" className="dark-btn" type="primary" onClick={() => onClickOTP()}>
                                    Submit
                                </Button>
                            </Form.Item>


                            {/* <Form.Item style={{ marginTop: "1em", textAlign: 'center' }}  >
                                <a onClick={() => navigation("/sign-up")} >New to platform? create an account</a>
                            </Form.Item> */}
                        </Form>
                    </Col>
                </Row>
            </Col>


            <Row justify="center" align="middle" gutter={[30]}>


                <Typography.Title type="secondary" level={5}>
                    Powered by <img style={{ marginLeft: 5 }} src={Logo1} alt="logo" />
                </Typography.Title>


            </Row>

        </Row>
    );
}
